import React from "react";
import tw from "twin.macro";
import { Link, graphql } from "gatsby";
import Layout from "./layout";
import Tag from "./tag";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const tagTo = `/tags/${tag}`;
  const { edges, totalCount } = data.allMdx;
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
  } with`;

  return (
    <Layout>
      <h1 tw="text-2xl leading-10 font-bold mt-6">
        {tagHeader}<Tag to={tagTo}>{tag}</Tag>
      </h1>
      <hr tw="border my-2"/>
      {edges.map(({ node }) => {
        const { slug } = node.fields;
        const { title } = node.frontmatter;
        const { excerpt } = node;
        return (
          <div key={slug}>
            <Link tw="text-blue-ocean" to={slug}><h2 tw="text-xl leading-9 font-bold mt-5">{title}</h2></Link>
            <p>{excerpt}</p>
          </div>);
      })}
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
query ($tag: String) {
  allMdx(sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {tags: {in: [$tag]}}}) {
    totalCount
    edges {
      node {
        id
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
        }

      }
    }
  }
}
`;
